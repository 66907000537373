.image-gallery-image {
    width: 1000px; /* ou la largeur souhaitée */
    height: 500px; /* ou la hauteur souhaitée */
    object-fit: cover; /* Pour s'assurer que l'image remplit le conteneur */
}



  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: cover;
  }